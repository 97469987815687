import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ApiService } from "src/shared/api.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AuthService } from "src/shared/auth.service";

@Component({
	selector: "app-transportation-order-dialog",
	templateUrl: "./transportation-order-dialog.component.html",
	styleUrls: ["./transportation-order-dialog.component.scss"]
})
export class TransportationOrderDialogComponent implements OnInit {
	currentService: any;
	dumpService: any;
	currentDate = new Date();
	price = 0;
	availability = 0;
	options = [];
	selectedOption = undefined;
	merchants = [];
	selectedMerchant = undefined;
	destinations: any;
	destinationQuery = "";



	@ViewChild("destinationSelect") destinationSelect;


	constructor(
		public dialogRef: MatDialogRef<TransportationOrderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public object: any,
		private apiService: ApiService,
		private authService: AuthService
	) { }

	transportationOrderFormGroup: FormGroup;
	destinationSearchControl = new FormControl("");
	destinationControl = new FormControl("", [Validators.required]);
	quantityControl = new FormControl("", [
		Validators.required,
		Validators.min(1)
	]);
	usedDateControl = new FormControl(this.currentDate, [Validators.required]);
	nameControl = new FormControl("");
	remarkControl = new FormControl("");
	addressControl = new FormControl("");
	contactNumberControl = new FormControl("");
	amountControl = new FormControl("", [Validators.required]);

	getQuantityErrorMessage() {
		return this.quantityControl.hasError("required")
			? "You must enter a value"
			: this.quantityControl.hasError("max")
				? "Quantity cannot exceed available ticket"
				: this.quantityControl.hasError("min")
					? "Quantity must be at least 1"
					: "";
	}

	getRequiredErrorMessage(field) {
		return this.transportationOrderFormGroup.get(field).hasError("required")
			? "You must enter a value"
			: "";
	}

	ngOnInit() {
		console.log("transportation: ", this.object);
		this.transportationOrderFormGroup = new FormGroup({
			destinationControl: this.destinationControl,
			quantityControl: this.quantityControl,
			usedDateControl: this.usedDateControl,
			nameControl: this.nameControl,
			addressControl: this.addressControl,
			contactNumberControl: this.contactNumberControl,
			amountControl: this.amountControl,
			destinationSearchControl: this.destinationSearchControl,
			remarkControl: this.remarkControl
		});
		// this.amountControl.disable();
		this.usedDateControl.disable();
		// this.quantityControl.setValue(1);

		this.currentService = this.object.transportation;
		this.dumpService = this.object.transportation;

		if (this.object.is_back) {
			this.destinationControl.setValue(this.object.destination);
			this.quantityControl.setValue(this.object.quantity);
			this.usedDateControl.setValue(new Date(this.object.used_date));
			this.nameControl.setValue(this.object.buyer_name);
			this.addressControl.setValue(this.object.buyer_address);
			this.contactNumberControl.setValue(this.object.buyer_phone_number);
			this.remarkControl.setValue(this.object.remark);
			this.price = this.object.price;
			this.availability = this.object.availability;
			this.calculateAmount();
			this.getOptions(this.currentService._id, this.destinationControl.value);
			this.selectedOption = this.object.option;
		}

		this.getDestinations();
		this.getMerchants(this.currentService._id);
	}



	getDestinations() {
		this.apiService.getDestinationsByService(this.currentService._id).subscribe(
			(res: any) => {
				this.destinations = res;

				console.log('destinations: ', this.destinations);
			},
			err => {
				console.log(err);
			}
		);
	}

	getOptions(service_id, destination_id) {
		console.log("GET MERCHANT BY SERVICE");
		if (destination_id != "") {
			this.apiService.getOptionsByServiceAndDestination(service_id, destination_id).subscribe((res: any) => {
				this.options = res;
				if (!this.object.is_back) {
					this.selectedOption = this.options[0];
				} else {
					this.object.is_back = false;
				}

				this.price = this.selectedOption ? this.selectedOption.tourist_price : 0;
				this.getAvailability();

				console.log('options: ', this.options);
				if (this.options.length > 0) {
					this.usedDateControl.enable();
					this.calculateAmount();
				} else {
					this.usedDateControl.disable();
				}
			});
		}
	}

	getMerchants(service_id) {
		this.apiService
			.getMerchantsByService(service_id)
			.subscribe((res: any) => {
				this.merchants = res;
				this.selectedMerchant = this.merchants[0];
				// this.onSelectMerchant(this.merchants[0]);
				this.getAvailability();
				console.log("merchants: ", this.merchants);
			});
	}

	onSelectOption(option) {
		console.log("ON SELECT OPTION");
		this.selectedOption = option;
		this.price = this.selectedOption.tourist_price;
		this.getAvailability();
	}

	getAvailability() {
		const date = new Date(this.usedDateControl.value).toISOString();
		if (this.selectedMerchant == undefined || this.selectedOption == undefined) {
			this.availability = 0;
			this.quantityControl.setValidators([Validators.max(this.availability)]);
			console.log('not request availability');
		} else {
			this.apiService.getAvailability(this.selectedOption._id, this.selectedMerchant._id, date).subscribe((res: any) => {
				console.log("availability: ", res);
				this.availability = res.availability_number;
				this.quantityControl.setValidators([Validators.max(this.availability)]);
			});
		}
	}

	onSelectMerchant(merchant) {
		this.selectedMerchant = merchant;
		this.getAvailability();
		this.calculateAmount();
	}

	onSearchDestinationChange(event) {
		this.destinationQuery = event.target.value;
	}

	onDestinationToggle() {
		console.log("onDestinationToggle");
		this.destinationSearchControl
			.setValue("");
		this.destinationQuery = "";
		this.getOptions(this.currentService._id, this.destinationControl.value);

	}

	onDestinationSearchChange(event) {
		this.destinationQuery = event.target.value;
	}

	onDestinationCloseClick() {
		if (
			this.destinationSearchControl
				.value === ""
		) {
			this.destinationSelect.close();
		} else {
			this.destinationSearchControl
				.setValue("");
			this.destinationQuery = "";
		}
	}

	onQtyChange() {
		this.calculateAmount();
	}

	calculateAmount() {
		let qty = this.quantityControl.value;
		if (!this.quantityControl.invalid) {
			const amount = this.price * qty || 0;
			this.amountControl.setValidators([
				Validators.required,
				Validators.min(this.price)
			]);
			this.amountControl.setValue(amount);
		} else {
			this.amountControl.setValue(0);
			this.amountControl.setValidators([
				Validators.required,
				Validators.min(this.price)
			]);
		}
		this.transportationOrderFormGroup.updateValueAndValidity();
	}

	onUsedDateChange() {
		this.getOptions(this.currentService._id, this.destinationControl.value);
		this.calculateAmount();
	}

	onCancel() {
		this.dialogRef.close();
	}

	placeOrder() {
		this.object.transportation.merchant = this.selectedMerchant;
		const orderDetail = {
			used_date: new Date(
				this.usedDateControl.value
			).toISOString(),
			buyer_name:
				this.nameControl.value || "N/A",
			buyer_address:
				this.addressControl.value || "N/A",
			buyer_phone_number:
				this.contactNumberControl.value ||
				"N/A",
			destination: this.destinationControl
				.value,
			quantity: this.quantityControl.value,
			service: this.object.transportation,
			amount: this.amountControl.value,
			option: this.selectedOption,
			price: this.price,
			merchant: this.selectedMerchant,
			availability: this.availability,
			remark: this.remarkControl.value || "N/A"
		};
		this.dialogRef.close(orderDetail);
	}

	isDigit(event) {
		const inputKeyCode = event.keyCode ? event.keyCode : event.which;
		if (inputKeyCode !== null) {
			if (inputKeyCode === 45) {
				event.preventDefault();
			}
			if (inputKeyCode < 48 || inputKeyCode > 57) {
				event.preventDefault();
			}
		}
	}
}
