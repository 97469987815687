import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/shared/api.service";
import { AuthService } from "src/shared/auth.service";
import { MatDialog } from "@angular/material";
import { CloseSaleShiftDialogComponent } from "./close-sale-shift-dialog/close-sale-shift-dialog.component";
import { NotificationService } from "src/shared/notification.service";
import { TicketComponent } from "./ticket/ticket.component";
import { SaleShiftDialogComponent } from "./sale-shift-dialog/sale-shift-dialog.component";
import { ChangePasswordDialogComponent } from "./change-password-dialog/change-password-dialog.component";
import { InteractionService } from "src/shared/interaction.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  saleShift: any;
  exchangeRates: any;
  amount_cash_riel = 0;
  amount_cash_dollar = 0;
  username = "";
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private interactionService: InteractionService
  ) {
    console.log("is_close_sale: ", authService.getIsCloseSaleShift());
    console.log("is_open_sale: ", authService.getIsOpenSaleShift());
    if (this.authService.getIsOpenSaleShift()) {
      this.openSaleShiftDialog();
    }
  }

  ngOnInit() {
    this.getExchangeRate();
    this.getCurrentCash();
    this.username = this.authService.getUsername();
    this.interactionService.message$.subscribe(message => {
      if (message === 2) {
        this.getCurrentCash();
      }
    });
  }

  getExchangeRate() {
    this.exchangeRates = [];
    this.apiService.getCurrencies().subscribe(
      (res: any) => {
        if (res.length > 0) {
          this.exchangeRates = res;
          this.authService.setExchangeRate(res);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getCurrentCash() {
    let sale_shift_id = this.authService.getUserID();
    let saleShift = this.authService.getSaleShift();
    if (saleShift != null) {
      sale_shift_id = saleShift._id;
    }
    this.apiService.getCurrentCash(sale_shift_id).subscribe(
      (res: any) => {
        this.amount_cash_dollar = res.amount_dollar;
        this.amount_cash_riel = res.amount_riel;
      },
      error => {
        console.log(error);
      }
    );
  }

  logout() {
    this.authService.logout();
  }

  showReport() {
    window.open("report", "_blank");
  }

  openSaleShiftDialog() {
    const dialogRef = this.dialog.open(SaleShiftDialogComponent, {
      width: "600px",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.authService.setSaleShift(result.sale_shift);
      // this.selectedMerchant = this.dumData;
      // this.getAllMerchants();
      // this.getPopularTickets();
      this.getExchangeRate();
      this.getCurrentCash();
    });
  }

  openCloseSaleDialog() {
    console.log("openCloseSaleDialog", this.authService.getIsCloseSaleShift());
    if (!this.authService.getIsCloseSaleShift()) {
      const dialogRef = this.dialog.open(CloseSaleShiftDialogComponent, {
        width: "600px"
      });
    } else {
      this.notificationService.showFailureNotification(
        "Cannot close sale. You have already closed it."
      );
    }
  }

  openChangePasswordDialog() {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: "600px",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  receiveMessageFromChild(event) {
    console.log("event from child: ", event);
  }
}
