import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ApiService } from "src/shared/api.service";
@Component({
	selector: "app-guide-order-dialog",
	templateUrl: "./guide-order-dialog.component.html",
	styleUrls: ["./guide-order-dialog.component.scss"]
})
export class GuideOrderDialogComponent implements OnInit {
	guide: any;
	currentDate = new Date();
	options = [];
	price = 0;
	numberDays = 1;
	selectedOption = undefined;
	fromDate: any;
	toDate: any;
	constructor(
		public dialogRef: MatDialogRef<GuideOrderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public object: any,
		private apiService: ApiService,
	) { }

	guideOrderFormGroup: FormGroup;
	startDateControl = new FormControl(this.object.from_date, [
		Validators.required
	]);
	endDateControl = new FormControl(this.object.to_date, [Validators.required]);
	// touristNumberControl = new FormControl("", [Validators.required]);
	contactNumberControl = new FormControl("");
	remarkControl = new FormControl("");
	nameControl = new FormControl("");
	locationControl = new FormControl("");
	amountControl = new FormControl("", [
		Validators.required,
		Validators.min(0)
	]);

	getRequiredErrorMessage(field) {
		return this.guideOrderFormGroup.get(field).hasError("required")
			? "You must enter a value"
			: "";
	}

	ngOnInit() {
		console.log("order_data: ", this.object);
		this.guideOrderFormGroup = new FormGroup({
			startDateControl: this.startDateControl,
			endDateControl: this.endDateControl,
			contactNumberControl: this.contactNumberControl,
			// touristNumberControl: this.touristNumberControl,
			nameControl: this.nameControl,
			locationControl: this.locationControl,
			amountControl: this.amountControl,
			remarkControl: this.remarkControl
		});

		this.guide = this.object.guide;
		this.fromDate = this.object.from_date;
		// this.startDateControl.setValue(this.object.fromDate);
		this.toDate = this.object.to_date;
		// this.endDateControl.setValue(this.object.toDate);
		if (this.object.is_back) {
			this.amountControl.setValue(
				this.object.amount
			);
			this.nameControl.setValue(this.object.buyer_name);
			this.locationControl.setValue(this.object.buyer_address);
			this.contactNumberControl.setValue(this.object.buyer_phone_number);
			this.remarkControl.setValue(this.object.remark);
			this.price = this.object.price;
			this.selectedOption = this.object.option;
		}

		this.getOptions(this.object.language, this.object.destination);
	}

	getOptions(service_id, destination_id) {
		console.log("GET MERCHANT BY SERVICE");
		this.apiService.getOptionsByServiceAndDestination(service_id, destination_id).subscribe((res: any) => {
			this.options = res;
			if (this.selectedOption == undefined) {
				this.selectedOption = this.options[0];
			}
			this.price = this.selectedOption == undefined ? 0 : this.selectedOption.tourist_price;
			this.calculateTotalAmount(
				this.fromDate,
				this.toDate,
				this.price
			)
		});
	}

	onSelectOption(option) {
		this.selectedOption = option;
		this.price = this.selectedOption.tourist_price;
		// this.getAvailability();
		this.calculateTotalAmount(
			this.fromDate,
			this.toDate,
			this.price
		)
	}

	onStartDateChange(event) {
		this.fromDate = event.target.value;
		this.amountControl.setValue(
			this.calculateTotalAmount(
				this.fromDate,
				this.toDate,
				this.price
			)
		);
	}

	onEndDateChange(event) {
		this.toDate = event.target.value;
		this.amountControl.setValue(
			this.calculateTotalAmount(
				this.fromDate,
				this.toDate,
				this.price
			)
		);
	}

	onCancel() {
		this.dialogRef.close();
	}

	placeOrder() {
		const orderData = {
			from_date: new Date(this.startDateControl.value).toISOString(),
			to_date: new Date(this.endDateControl.value).toISOString(),
			used_date: new Date(this.startDateControl.value).toISOString(),
			buyer_name: this.nameControl.value || "N/A",
			buyer_address: this.locationControl.value || "N/A",
			buyer_phone_number: this.contactNumberControl.value || "N/A",
			amount: this.amountControl.value,
			price: this.price,
			language: this.object.language,
			destination: this.object.destination,
			merchant: this.object.guide,
			guide: this.guide,
			option: this.selectedOption,
			number_days: this.numberDays,
			remark: this.remarkControl.value || "N/A"
		};
		this.dialogRef.close(orderData);
	}

	calculateTotalAmount(startDate: any, endDate: any, price: any) {
		console.log('in calculate amount', price);
		const date1 = new Date(new Date(startDate).setHours(0, 0, 0, 0));
		const date2 = new Date(new Date(endDate).setHours(23, 59, 59, 59));
		if (date1.getTime() > date2.getTime()) {
			this.amountControl.setValue(0)
		} else {
			const timeDiff = Math.abs(date2.getTime() - date1.getTime());
			const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
			this.numberDays = dayDiff;
			let amount = dayDiff * price;
			console.log(amount);
			this.amountControl.setValue(amount);
		}
	}
}
