import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  API_URL = environment.url;
  constructor(private http: HttpClient, private authService: AuthService) { }

  // Merchant http request
  public getAllMerchants() {
    return this.http.get(
      `${
      this.API_URL
      }merchants/pos?token=${this.authService.getToken()}&service_type=ticket&parent=null`
    );
  }

  public getDestinationTickets() {
    return this.http.get(
      `${
      this.API_URL
      }destinations?token=${this.authService.getToken()}&service_type_id=5cd103ceffed32019012273a`
    );
  }

  public getOptionsByServiceAndDestination(service_id: any, destination_id: any) {
    return this.http.get(
      `${
      this.API_URL
      }options?token=${this.authService.getToken()}&service=${service_id}&destination=${destination_id}`
    );
  }

  public getAvailability(option_id, merchant_id, date) {
    return this.http.get(
      `${
      this.API_URL
      }availability?token=${this.authService.getToken()}&merchant=${merchant_id}&option=${option_id}&date=${date}`
    );
  }

  public getMerchantsByService(service_id: any) {
    return this.http.get(
      `${
      this.API_URL
      }merchant_services?token=${this.authService.getToken()}&service_id=${service_id}`
    );
  }

  public getDestinationsByService(service_id: any) {
    return this.http.get(
      `${
      this.API_URL
      }destination_services?token=${this.authService.getToken()}&service=${service_id}`
    );
  }

  public getMerchantsByServiceAndDestination(
    service_id: any,
    destination_id: any
  ) {
    return this.http.get(
      `${
      this.API_URL
      }merchant_services?token=${this.authService.getToken()}&service_id=${service_id}&destination_id=${destination_id}`
    );
  }
  public getAllServices(search: string = "") {
    let query = `?token=${this.authService.getToken()}`;
    if (search !== "" && search !== undefined) {
      query = query + `&title=${search}`;
    }
    return this.http.get(`${this.API_URL}services/pos${query}`);
  }
  // Ticket http request
  public getTicketsList(
    merchant_id: string = "",
    date: any = "",
    search: string = ""
  ) {
    let query = `?token=${this.authService.getToken()}`;
    if (merchant_id !== "" && merchant_id !== undefined) {
      query = query + `&merchant_id=${merchant_id}`;
    }

    if (date !== "" && date !== undefined) {
      query = query + `&date=${date}`;
    }

    if (search !== "" && search !== undefined) {
      query = query + `&title=${search}`;
    }

    return this.http.get(`${this.API_URL}services/pos${query}`);
  }

  public getServiceByDestination(destination: string = "") {
    let query = `?token=${this.authService.getToken()}`;
    if (destination !== "") {
      query = query + "&destination=" + destination;
    }
    return this.http.get(`${this.API_URL}destination_services${query}`);
  }

  public getSpecificTicketByDate(
    ticketID: string,
    merchantId: string,
    date: any
  ) {
    return this.http.get(
      `${
      this.API_URL
      }services/pos/${ticketID}?token=${this.authService.getToken()}&date=${date}&merchant_id=${merchantId}`
    );
  }

  public getSpecificTicketByDateAndDestination(
    ticketID: string,
    merchantId: string,
    date: any,
    destination: string
  ) {
    return this.http.get(
      `${
      this.API_URL
      }services/pos/${ticketID}?token=${this.authService.getToken()}&date=${date}&merchant_id=${merchantId}&destination=${destination}`
    );
  }

  public getPopularTickets(date: any) {
    return this.http.get(
      `${
      this.API_URL
      }services/popular?token=${this.authService.getToken()}&date=${date}`
    );
  }
  public getCurrentCash(sale_shift_id) {
    return this.http.get(
      `${
      this.API_URL
      }sale_shifts/${sale_shift_id}/current_cash?token=${this.authService.getToken()}`
    );
  }
  // Currency http request
  public getCurrencies() {
    return this.http.get(
      `${this.API_URL}exchange_rates?token=${this.authService.getToken()}`
    );
  }

  // order http request
  public createOrder(order: any) {
    return this.http.post(
      `${this.API_URL}orders?token=${this.authService.getToken()}`,
      order
    );
  }

  public getOrders(
    startDate: any,
    endDate: any,
    page: any = 1,
    count: any = 10,
    sale_shift_id = null
  ) {
    let query = `start_date=${startDate}&end_date=${endDate}&page=${page}&count=${count}`;
    if (sale_shift_id != null) {
      // console.log("sale_shift_id,", sale_shift_id);
      query = query + "&sale_shift=" + sale_shift_id;
    }
    // console.log("query", query);
    return this.http.get(
      `${this.API_URL}orders?token=${this.authService.getToken()}&${query}`
    );
  }

  cancelOrder(id: string) {
    const body = {
      order_status: "cancelled"
    };
    return this.http.patch(
      `${this.API_URL}orders/cancel/${id}?token=${this.authService.getToken()}`,
      body
    );
  }

  // print log http request
  public createPrintLog(logInfo: any) {
    return this.http.post(
      `${this.API_URL}print_logs?token=${this.authService.getToken()}`,
      logInfo
    );
  }

  getMerchantService(merchant, service) {
    return this.http.get(
      `${
      this.API_URL
      }merchant_services?token=${this.authService.getToken()}&merchant_id=${merchant}&service=${service}`
    );
  }

  // sale shift http request
  openSaleShift(data: any) {
    return this.http.post(
      `${this.API_URL}sale_shifts?token=${this.authService.getToken()}`,
      data
    );
  }

  closeSaleShift(id: any, data: any) {
    return this.http.patch(
      `${this.API_URL}sale_shifts/${id}?token=${this.authService.getToken()}`,
      data
    );
  }

  // find tour guide http request
  getLanguages() {
    return this.http.get(
      `${this.API_URL}languages?token=${this.authService.getToken()}`
    );
  }

  getDestinations() {
    return this.http.get(
      `${this.API_URL}destinations?token=${this.authService.getToken()}`
    );
  }

  getTourGuides(
    destination: string,
    fromDate: any,
    toDate: any,
    language: any
  ) {
    let query = `?token=${this.authService.getToken()}`;
    if (destination !== "" && destination !== undefined) {
      query = query + `&destination=${destination}`;
    }
    if (fromDate !== "" && fromDate !== undefined) {
      query = query + `&from_date=${fromDate}`;
    }

    if (toDate !== "" && toDate !== undefined) {
      query = query + `&to_date=${toDate}`;
    }

    if (language !== "" && language !== undefined) {
      query = query + `&language=${language}`;
    }
    return this.http.get(`${this.API_URL}tour_guide/find${query}`);
  }

  // transportation http request
  getAllTransportations() {
    return this.http.get(
      `${
      this.API_URL
      }services/pos?token=${this.authService.getToken()}&service_type=5cf893dd593f8a5b787bc55c`
    );
  }

  getServiceLanguages() {
    return this.http.get(
      `${
      this.API_URL
      }services/pos?token=${this.authService.getToken()}&service_type=5cd103ffffed32019012273b`
    );
  }
}
