import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ApiService } from "src/shared/api.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "src/shared/auth.service";
import { OrderDialogComponent } from "../order-dialog/order-dialog.component";
import { InteractionService } from "src/shared/interaction.service";
import { PrintDialogComponent } from "../print-dialog/print-dialog.component";

@Component({
  selector: "app-payment-dialog",
  templateUrl: "./payment-dialog.component.html",
  styleUrls: ["./payment-dialog.component.scss"]
})
export class PaymentDialogComponent implements OnInit {
  currencies: any;
  exchange_rate_dollar_to_riel = 0;
  exchange_rate_yuan_riel = 0;
  date = new Date().getTime();
  serviceMerchant: any;
  amount_riel = 0;
  amount_dollar = 0;
  amount_yuan = 0;
  isLoading = false;
  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private authService: AuthService,
    private dialog: MatDialog,
    private interactionService: InteractionService
  ) { }

  payFormGroup: FormGroup;
  cashReceivedUSDFormControl = new FormControl("", [Validators.required]);
  cashReceivedKHRFormControl = new FormControl("", [Validators.required]);
  changeUSDFormControl = new FormControl(0, [Validators.required]);
  changeKHRFormControl = new FormControl(0, [Validators.required]);

  getRequiredErrorMessage(field) {
    return this.payFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  ngOnInit() {
    console.log("payment: ", this.object);
    this.getCurrencies();
    this.payFormGroup = new FormGroup({
      cash_received_usd: this.cashReceivedUSDFormControl,
      cash_received_khr: this.cashReceivedKHRFormControl,
      change_usd: this.changeUSDFormControl,
      change_khr: this.changeKHRFormControl
    });

    this.amount_riel =
      this.object.order.quantity *
      this.object.order.price *
      this.exchange_rate_dollar_to_riel;
    this.amount_dollar =
      this.object.order.quantity *
      this.object.order.price;
    this.amount_yuan =
      this.object.order.quantity *
      this.object.order.price *
      this.exchange_rate_yuan_riel;

    console.log("amount_riel", this.amount_riel);
    console.log("amount_dollar", this.amount_dollar);
    console.log("amount_yuan", this.amount_yuan);
  }

  getCurrencies() {
    this.currencies = this.authService.getExchangeRate();
    this.exchange_rate_dollar_to_riel = this.currencies[0].to_riel;
    this.exchange_rate_yuan_riel = this.currencies[1].to_riel;
  }

  formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
          Math.abs(amount - +i)
            .toFixed(decimalCount)
            .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  }

  calculateChange() {
    let cashReceivedUSD = this.payFormGroup.get("cash_received_usd").value;
    let cashReceivedKHR = this.payFormGroup.get("cash_received_khr").value;
    let totalReceived =
      Number(cashReceivedUSD) +
      Number(cashReceivedKHR / this.exchange_rate_dollar_to_riel);
    let change = totalReceived - this.amount_dollar;
    if (Number(change) >= 0) {
      this.payFormGroup
        .get("change_usd")
        .setValue(Math.round(change * 100) / 100);
      this.payFormGroup
        .get("change_khr")
        .setValue(Math.floor(change * this.exchange_rate_dollar_to_riel));
    } else {
      this.payFormGroup.get("change_usd").setValue(null);
      this.payFormGroup.get("change_khr").setValue(null);
    }
    this.payFormGroup.updateValueAndValidity();
  }

  onUSDCashReceivedChange() {
    const receivedUSD = this.payFormGroup.get("cash_received_usd").value;
    if (Number(receivedUSD) < this.amount_dollar) {
      const neededKHR =
        (Number(this.amount_dollar) - Number(receivedUSD)) *
        this.exchange_rate_dollar_to_riel;
      this.cashReceivedKHRFormControl.setValue(neededKHR);
    } else {
      this.cashReceivedKHRFormControl.setValue(0);
    }
    this.calculateChange();
  }

  onKHRCashReceivedChange() {
    const receivedKHR = this.payFormGroup.get("cash_received_khr").value;
    if (
      Number(Math.round(receivedKHR / this.exchange_rate_dollar_to_riel)) <
      Number(this.amount_dollar)
    ) {
      const neededUSD =
        Number(this.amount_dollar) -
        Number(Math.round(receivedKHR / this.exchange_rate_dollar_to_riel));
      this.cashReceivedUSDFormControl.setValue(neededUSD);
    } else {
      this.cashReceivedUSDFormControl.setValue(0);
    }
    this.calculateChange();
  }

  submitPayment() {
    const paymentData = {

      order_items: [
        {
          quantity: this.object.order.quantity,
          total_price: this.amount_dollar,
          original_total_price: this.object.order.option.original_price * this.object.order.quantity,
          from_date: this.object.order.used_date,
          to_date: this.object.order.used_date,
          used_date: this.object.order.used_date,
          remark: this.object.order.remark,
          option: this.object.order.option,
          merchant: this.object.order.merchant._id,
          type: "tourist",
        }
      ],


      total_amount: this.amount_dollar,
      amount_riel: this.amount_riel,
      amount_dollar: this.amount_dollar,
      amount_yuan: this.amount_yuan,

      exchange_rate: this.currencies[0],

      buyer_name: this.object.order.buyer_name,
      buyer_phone_number: this.object.order.buyer_phone_number,
      buyer_address: this.object.order.buyer_address,

      pos: this.authService.getUserID(),
      sale_shift: this.authService.getSaleShift()._id,

      received_cash_dollar: this.payFormGroup.get("cash_received_usd").value,
      received_cash_riel: this.payFormGroup.get("cash_received_khr").value,
      received_cash_yuan: 0,


      dollar_change: this.payFormGroup.get("change_usd").value,
      riel_change: this.payFormGroup.get("change_khr").value,
      yuan_change: 0,
    };
    console.log(paymentData);
    let printData: any = null;
    this.isLoading = true;
    this.apiService.createOrder(paymentData).subscribe(
      res => {
        console.log(res);
        paymentData['service'] = this.object.order.service;
        printData = {
          response: res,
          payment_data: paymentData
        };
        console.log("printData", printData);
        this.dialogRef.close(printData);
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.dialogRef.close();
      }
    );
  }

  openOrderDialog() {
    const data = {
      used_date: this.object.order.used_date,
      quantity: this.object.order.quantity,
      buyer_name: this.object.order.buyer_name,
      buyer_address: this.object.order.buyer_address,
      buyer_phone_number: this.object.order.buyer_phone_number,
      merchant: this.object.order.merchant,
      option: this.object.order.option,
      amount: this.object.order.amount,
      remark: this.object.order.remark
    };
    console.log("previous_data", data);

    const dialogRef = this.dialog.open(OrderDialogComponent, {
      width: "1200px",
      disableClose: true,
      data: {
        is_back: true,
        ticket: this.object.order.service,
        previous_data: data
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.openPaymentDialog(result);
      }
    });
  }

  openPaymentDialog(orderData) {
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      width: "1200px",
      disableClose: true,
      height: "800px",
      data: {
        order: orderData
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result.response.status === 201) {
          // this.getTicketsByMerchant();
          this.interactionService.sendMessage(1);
        }
        if (result !== null && result !== undefined) {
          this.openPrintDialog(result);
        }
      }
    });
  }

  openPrintDialog(data: any) {
    const dialogRef = this.dialog.open(PrintDialogComponent, {
      width: "600px",
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.interactionService.sendMessage(2);
      // this.getCurrentCash();
    });
  }

  onBack() {
    this.dialogRef.close();
    this.openOrderDialog();
  }
}
