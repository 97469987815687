import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ApiService } from "src/shared/api.service";
import { AuthService } from "src/shared/auth.service";
import { isNgTemplate, ThrowStmt } from "@angular/compiler";
import { tick } from '@angular/core/src/render3';

@Component({
  selector: "app-order-dialog",
  templateUrl: "./order-dialog.component.html",
  styleUrls: ["./order-dialog.component.scss"]
})
export class OrderDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<OrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private authService: AuthService
  ) { }
  currentService: any;
  selectedCurrency: any;
  merchantServices = [];
  selectedMerchantService: any;
  options = [];
  availability = 0;
  price = 0;
  selectedOption = undefined;
  merchants = [];
  selectedMerchant = undefined;

  date: Date = new Date();
  currentDate: Date = new Date();
  orderFormGroup: FormGroup;
  usedDateFormControl: FormControl = new FormControl(this.date, [
    Validators.required
  ]);
  quantityFormControl: FormControl = new FormControl("", [
    Validators.required,
    Validators.min(1)
  ]);
  buyerNameFormControl: FormControl = new FormControl("");
  remarkFormControl: FormControl = new FormControl("");
  buyerAddressFormControl: FormControl = new FormControl("");
  buyerPhoneNumberFormControl: FormControl = new FormControl("");
  amountFormControl: FormControl = new FormControl("", [
    Validators.required,
    Validators.min(1)
  ]);

  ngOnInit() {
    this.orderFormGroup = new FormGroup({
      used_date: this.usedDateFormControl,
      quantity: this.quantityFormControl,
      buyer_name: this.buyerNameFormControl,
      buyer_address: this.buyerAddressFormControl,
      buyer_phone_number: this.buyerPhoneNumberFormControl,
      amount: this.amountFormControl,
      remark: this.remarkFormControl,
    });
    console.log("order: ", this.object);
    this.currentService = this.object.ticket;


    if (this.object.is_back) {
      this.selectedMerchant = this.object.previous_data.merchant;
      this.selectedOption = this.object.previous_data.option;
      this.usedDateFormControl
        .setValue(new Date(this.object.previous_data.used_date));
      this.quantityFormControl
        .setValue(this.object.previous_data.quantity);
      this.amountFormControl
        .setValue(this.object.previous_data.amount);
      this.buyerNameFormControl
        .setValue(this.object.previous_data.buyer_name);
      this.buyerAddressFormControl
        .setValue(this.object.previous_data.buyer_address);
      this.buyerPhoneNumberFormControl
        .setValue(this.object.previous_data.buyer_phone_number);
      this.remarkFormControl.setValue(this.object.previous_data.remark);
    }

    console.log("currentService: ", this.currentService);
    this.getOptions(this.currentService._id, this.currentService.destination._id);
    this.getMerchants(this.currentService._id);

    this.merchants = [];
    this.merchantServices = [];
  }

  getQuantityErrorMessage() {
    return this.quantityFormControl.hasError("required")
      ? "You must enter a value"
      : this.quantityFormControl.hasError("max")
        ? "Quantity cannot exceed available ticket"
        : this.quantityFormControl.hasError("min")
          ? "Quantity must be at least 1"
          : "";
  }

  getMerchants(service_id) {
    console.log("GET MERCHANT BY SERVICE");
    this.apiService.getMerchantsByService(service_id).subscribe((res: any) => {
      this.merchants = res;
      this.selectedMerchant = this.merchants[0];
      this.getAvailability();
    });
  }

  getOptions(service_id, destination_id) {
    console.log("GET MERCHANT BY SERVICE");
    this.apiService.getOptionsByServiceAndDestination(service_id, destination_id).subscribe((res: any) => {
      this.options = res;
      if (this.selectedOption == undefined) {
        this.selectedOption = this.options[0];
      }
      this.price = this.selectedOption.tourist_price;
      this.getAvailability();
    });
  }

  onSelectOption(option) {
    this.selectedOption = option;
    this.price = this.selectedOption.tourist_price;
    this.getAvailability();
    this.calculateAmount();
  }

  onSelectMerchant(merchant) {
    this.selectedMerchant = merchant;
    this.getAvailability();
  }

  getRequiredErrorMessage(field) {
    return this.orderFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }


  isDigit(event) {
    const inputKeyCode = event.keyCode ? event.keyCode : event.which;
    if (inputKeyCode !== null) {
      if (inputKeyCode === 45) {
        event.preventDefault();
      }
      if (inputKeyCode < 48 || inputKeyCode > 57) {
        event.preventDefault();
      }
    }
  }


  getAvailability() {
    const date = new Date(this.usedDateFormControl.value).toISOString();
    if (this.selectedMerchant == undefined || this.selectedOption == undefined) {
      this.availability = 0;
      this.quantityFormControl.setValidators([Validators.max(this.availability)]);
      console.log('not request availability');
    } else {
      this.apiService.getAvailability(this.selectedOption._id, this.selectedMerchant._id, date).subscribe((res: any) => {
        console.log(res);
        this.availability = res.availability_number;
        this.quantityFormControl.setValidators([Validators.max(this.availability)]);
      });
    }

  }

  onUsedDateChange() {
    this.getAvailability();
    const qty = this.quantityFormControl.value;
    if (qty > this.availability) {
      this.quantityFormControl.setValue(null);
    }
    this.quantityFormControl.setValidators([
      Validators.max(this.availability)
    ]);
    this.orderFormGroup.updateValueAndValidity();
  }

  onQtyChange() {
    this.calculateAmount();
  }

  calculateAmount() {
    let qty = this.quantityFormControl.value;
    if (!this.quantityFormControl.invalid) {
      this.amountFormControl
        .setValue(this.price * qty);
    } else {
      this.amountFormControl.setValue(null);
    }
    this.orderFormGroup.updateValueAndValidity();
  }

  placeOrder() {
    this.object.ticket.merchant = this.selectedMerchant;
    const orderData = {
      used_date: new Date(this.usedDateFormControl.value).toISOString(),
      buyer_name: this.buyerNameFormControl.value || "N/A",
      buyer_address: this.buyerAddressFormControl.value || "N/A",
      buyer_phone_number:
        this.buyerAddressFormControl.value || "N/A",
      quantity: this.quantityFormControl.value,
      service: this.object.ticket,
      merchant: this.selectedMerchant,
      option: this.selectedOption,
      remark: this.remarkFormControl.value || "N/A",
      price: this.price,
      amount: this.amountFormControl.value || 0
    };
    this.dialogRef.close(orderData);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
