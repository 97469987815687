import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchReport"
})
export class SearchReportPipe implements PipeTransform {
  transform(pipeData, pipeModifier): any {
    if (pipeData !== undefined && pipeData !== null) {
      return pipeData.filter(item => {
        // console.log(item);
        return item["order_items"][0].option.description
          .toLowerCase()
          .includes(pipeModifier.toLowerCase());
      });
    }
  }
}
