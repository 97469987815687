import { Component, OnInit } from "@angular/core";
import { NgxDrpOptions, PresetItem, Range } from "ngx-mat-daterange-picker";
import { ApiService } from "src/shared/api.service";
import { MatDialog } from "@angular/material";
import { PrintDialogComponent } from "../home/print-dialog/print-dialog.component";
import { AuthService } from "src/shared/auth.service";
import { NotificationService } from "src/shared/notification.service";
@Component({
  selector: "app-print-management",
  templateUrl: "./print-management.component.html",
  styleUrls: ["./print-management.component.scss"]
})
export class PrintManagementComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private authService: AuthService,
    private notificationService: NotificationService
  ) { }

  range: Range = { fromDate: new Date(), toDate: new Date() };
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  orders = [];
  totalTickets = 0;
  totalAmount = 0;
  currentPage = 1;
  count = 10;
  page_increment = 0;
  searchQuery = "";
  cancelId = "";

  ngOnInit() {
    const today = new Date();
    const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);

    this.setupPresets();
    this.options = {
      presets: this.presets,
      format: "mediumDate",
      range: { fromDate: today, toDate: today },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false
      }
      // cancelLabel: "Cancel",
      // excludeWeekends:true,
      // fromMinMax: {fromDate:fromMin, toDate:fromMax},
      // toMinMax: {fromDate:toMin, toDate:toMax}
    };
  }

  // handler function that receives the updated date range object
  updateRange(range: Range) {
    this.range = range;
    this.reload();
  }

  // helper function to create initial presets
  setupPresets() {
    const backDate = numOfDays => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {
        presetLabel: "Yesterday",
        range: { fromDate: yesterday, toDate: today }
      },
      {
        presetLabel: "Last 7 Days",
        range: { fromDate: minus7, toDate: today }
      },
      {
        presetLabel: "Last 30 Days",
        range: { fromDate: minus30, toDate: today }
      },
      {
        presetLabel: "This Month",
        range: { fromDate: currMonthStart, toDate: currMonthEnd }
      },
      {
        presetLabel: "Last Month",
        range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      }
    ];
  }

  reload() {
    this.orders = [];
    this.totalTickets = 0;
    this.totalAmount = 0;
    if (this.count === 0) {
      this.apiService
        .getOrders(this.range.fromDate.toISOString(), this.range.toDate.toISOString())
        .subscribe(
          (res: any) => {
            this.page_increment = (this.currentPage - 1) * this.count;
            this.orders = res;
            if (this.orders.length > 0) {
              this.orders.map(el => {
                this.totalTickets += el.order_items[0].quantity;
                this.totalAmount += el.amount_dollar;
              });
            }
          },
          error => {
            console.log(error);
          }
        );
    }
    this.apiService
      .getOrders(
        this.range.fromDate.toISOString(),
        this.range.toDate.toISOString(),
        this.currentPage,
        this.count
      )
      .subscribe(
        (res: any) => {
          this.page_increment = (this.currentPage - 1) * this.count;
          this.orders = res;
          console.log("report: ", this.orders);
          if (this.orders.length > 0) {
            this.orders.map(el => {
              this.totalTickets += el.order_items[0].quantity;
              this.totalAmount += el.amount_dollar;
            });
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  onPreviousClick() {
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload();
    }
  }

  onNextClick() {
    this.currentPage += 1;
    this.reload();
  }

  onCountChange() {
    this.reload();
  }

  noNext(data: any) {
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  // action section
  print(order) {
    console.log(order);
    const modifiedOrder = {
      payment_data: {
        buyer_name: order.buyer.name,
        ticket: {
          title: order.service.title
        },
        quantity: order.quantity,
        used_date: order.used_date,
        merchant_service: order.merchant_service
      },
      response: {
        data: {
          purchased_date: order.purchased_date,
          invoice_id: order.invoice_id,
          ticket: {
            price: order.merchant_service.tourist_price
          },
          amount_dollar: order.amount_dollar
        },
        order_id: order._id
      },
      reprint: true,
      pos: order.pos.user_id.name
    };
    console.log("modifiedOrder", modifiedOrder);
    this.openPrintDialog(modifiedOrder);
  }

  openPrintDialog(data: any) {
    const dialogRef = this.dialog.open(PrintDialogComponent, {
      width: "600px",
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  logout() {
    this.authService.logout();
  }

  onReloadClick() {
    this.reload();
  }

  confirmCancel(id: string) {
    this.cancelId = id;
  }

  onReallyCancelOrder() {
    this.apiService.cancelOrder(this.cancelId).subscribe(
      res => {
        this.notificationService.showSuccessNotification("Cancel Success.");
        this.reload();
      },
      err => {
        this.notificationService.showFailureNotification("Cancel Failed.");
      }
    );
  }
}
