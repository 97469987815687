import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "src/shared/api.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatSelect, MatDialog } from "@angular/material";
import { GuideOrderDialogComponent } from "../guide-order-dialog/guide-order-dialog.component";
import { GuidePaymentDialogComponent } from "../guide-payment-dialog/guide-payment-dialog.component";
import { GuidePrintDialogComponent } from "../guide-print-dialog/guide-print-dialog.component";
import { InteractionService } from "src/shared/interaction.service";

@Component({
  selector: "app-find-tour-guide",
  templateUrl: "./find-tour-guide.component.html",
  styleUrls: ["./find-tour-guide.component.scss"]
})
export class FindTourGuideComponent implements OnInit {
  destinations: any;
  languages: any;
  destinationQuery = "";
  languageQuery = "";
  startDate = new Date();
  endDate = new Date();
  currentDate = new Date();
  guides = [];
  isLoading = false;
  noData = false;
  @ViewChild("destinationSelect") destinationSelect;
  @ViewChild("languageSelect") languageSelect;
  guideSearchFormGroup: FormGroup;
  destinationControl = new FormControl("", [Validators.required]);
  startDateControl = new FormControl(this.currentDate, [Validators.required]);
  endDateControl = new FormControl(this.currentDate, [Validators.required]);
  languageControl = new FormControl("", [Validators.required]);
  destinationSearchControl = new FormControl();
  languageSearchControl = new FormControl();

  getRequiredErrorMessage(field) {
    return this.guideSearchFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private interactionService: InteractionService
  ) { }

  ngOnInit() {
    this.guideSearchFormGroup = new FormGroup({
      destinationControl: this.destinationControl,
      destinationSearchControl: this.destinationSearchControl,
      startDateControl: this.startDateControl,
      endDateControl: this.endDateControl,
      languageControl: this.languageControl,
      languageSearchControl: this.languageSearchControl
    });
    this.getDestinations();
    this.getLanguages();
    this.interactionService.message$.subscribe(message => {
      if (message === 1) {
        this.getDestinations();
      }
    });
  }

  getDestinations() {
    this.apiService.getDestinations().subscribe(
      (res: any) => {
        this.destinations = res;
      },
      err => {
        console.log(err);
      }
    );
  }

  getLanguages() {
    this.apiService.getServiceLanguages().subscribe(
      (res: any) => {
        console.log('languages: ', res)
        this.languages = res;
      },
      err => {
        console.log(err);
      }
    );
  }

  onSearchLanguageChange(event) {
    this.languageQuery = event.target.value;
  }

  onSearchDestinationChange(event) {
    this.destinationQuery = event.target.value;
  }

  onDestinationToggle() {
    this.guideSearchFormGroup.get("destinationSearchControl").setValue("");
    this.destinationQuery = "";
  }

  onLanguageToggle() {
    this.guideSearchFormGroup.get("languageSearchControl").setValue("");
    this.languageQuery = "";
  }

  onDestinationSearchChange(event) {
    this.destinationQuery = event.target.value;
  }

  onLanguageSearchChange(event) {
    this.languageQuery = event.target.value;
  }

  onDestinationCloseClick() {
    if (
      this.guideSearchFormGroup.get("destinationSearchControl").value === ""
    ) {
      this.destinationSelect.close();
    } else {

      this.guideSearchFormGroup.get("destinationSearchControl").setValue("");
      this.destinationQuery = "";
    }
  }

  onLanguageCloseClick() {
    if (this.guideSearchFormGroup.get("languageSearchControl").value === "") {
      this.languageSelect.close();
    } else {
      this.guideSearchFormGroup.get("languageSearchControl").setValue("");
      this.languageQuery = "";
    }
  }

  getTourGuides() {
    this.guides = [];
    this.isLoading = true;
    let fromDate = new Date(this.startDate);
    fromDate.setHours(0, 0, 0, 0);
    let toDate = new Date(this.endDate);
    toDate.setHours(23, 59, 59, 59);

    this.apiService
      .getTourGuides(
        this.destinationControl.value,
        fromDate.toISOString(),
        toDate.toISOString(),
        this.languageControl.value
      )
      .subscribe(
        (res: any) => {
          this.isLoading = false;
          this.guides = res;
          console.log("guides: ", res);
          if (this.guides.length <= 0) {
            this.noData = true;
          } else {
            this.noData = false;
          }
        },
        err => {
          this.isLoading = false;
          this.noData = true;
        }
      );
  }

  onSearchClick() {
    this.getTourGuides();
  }

  openOrderDialog(guide: any) {
    const dialogRef = this.dialog.open(GuideOrderDialogComponent, {
      width: "1200px",
      data: {
        is_back: false,
        guide: guide,
        destination: this.destinationControl.value,
        language: this.languageControl.value,
        from_date: this.startDate,
        to_date: this.endDate
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.openPaymentDialog(result);
      }
    });
  }

  openPaymentDialog(data: any) {
    const dialogRef = this.dialog.open(GuidePaymentDialogComponent, {
      width: "1200px",
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.openPrintDialog(result);
      }
    });
  }

  onStartDateChange(event) {
    this.startDate = event.target.value;
  }

  onEndDateChange(event) {
    this.endDate = event.target.value;
  }

  openPrintDialog(data: any) {
    const dialogRef = this.dialog.open(GuidePrintDialogComponent, {
      width: "600px",
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.getTourGuides();
      this.interactionService.sendMessage(2);
      // this.getCurrentCash();
    });
  }
}
