import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchMerchant"
})
export class SearchMerchantPipe implements PipeTransform {
  transform(pipeData, pipeModifier): any {
    if (pipeData !== undefined && pipeData !== null) {
      return pipeData.filter(item => {
        return item["name"].toLowerCase().includes(pipeModifier.toLowerCase());
      });
    }
  }
}
