import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";
import { AuthService } from "src/shared/auth.service";
import { NotificationService } from "src/shared/notification.service";

@Component({
  selector: "app-change-password-dialog",
  templateUrl: "./change-password-dialog.component.html",
  styleUrls: ["./change-password-dialog.component.scss"]
})
export class ChangePasswordDialogComponent implements OnInit {
  hide = true;
  hideNew = true;
  constructor(
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  changePasswordFormGroup: FormGroup;
  currentPasswordControl = new FormControl("", [Validators.required]);
  newPasswordControl = new FormControl("", [Validators.required]);

  getRequiredErrorMessage(field) {
    return this.changePasswordFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  ngOnInit() {
    this.changePasswordFormGroup = new FormGroup({
      current_password: this.currentPasswordControl,
      new_password: this.newPasswordControl
    });
  }

  onChangePassword() {
    const currentPassword = this.changePasswordFormGroup.get("current_password")
      .value;
    const newPassword = this.changePasswordFormGroup.get("new_password").value;
    const userID = this.authService.getUserID();
    this.authService
      .changePassword(userID, currentPassword, newPassword)
      .subscribe(
        respone => {
          this.dialogRef.close();
          this.authService.logout();
          this.notificationService.showSuccessNotification(
            "Change Password Successful. Please Login again."
          );
        },
        err => {
          this.dialogRef.close();
          this.notificationService.showFailureNotification(
            "Change Password Error."
          );
        }
      );
  }

  onCancel() {
    this.dialogRef.close();
  }
}
