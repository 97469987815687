import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class InteractionService {
  private _messageSource = new Subject<any>();
  message$ = this._messageSource.asObservable();

  constructor() {}

  sendMessage(message: any) {
    // send 1 to reload content
    // send 2 to get current cash
    this._messageSource.next(message);
  }
}
