import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "src/shared/auth.service";
import { MatDialogRef } from "@angular/material";
import { ApiService } from "src/shared/api.service";
import { NotificationService } from "src/shared/notification.service";

@Component({
  selector: "app-sale-shift-dialog",
  templateUrl: "./sale-shift-dialog.component.html",
  styleUrls: ["./sale-shift-dialog.component.scss"]
})
export class SaleShiftDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SaleShiftDialogComponent>,
    private authService: AuthService,
    private apiService: ApiService,
    private notificationService: NotificationService
  ) {}

  saleShiftFormGroup: FormGroup;
  cashInUSDControl = new FormControl("", [Validators.required]);
  cashInKHRControl = new FormControl("", [Validators.required]);

  getRequiredErrorMessage(field) {
    return this.saleShiftFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  ngOnInit() {
    this.saleShiftFormGroup = new FormGroup({
      cash_in_usd: this.cashInUSDControl,
      cash_in_khr: this.cashInKHRControl
    });
    this.saleShiftFormGroup.get("cash_in_usd").setValue(0);
    this.saleShiftFormGroup.get("cash_in_khr").setValue(0);
  }

  openSaleShift() {
    const data = {
      pos: this.authService.getUserID(),
      open_amount_dollar: this.saleShiftFormGroup.get("cash_in_usd").value,
      open_amount_riel: this.saleShiftFormGroup.get("cash_in_khr").value,
      open_date_time: Date.now()
    };
    this.apiService.openSaleShift(data).subscribe(
      (res: any) => {
        console.log(res);
        const saleShift = res.sale_shift;
        // localStorage.setItem("is_open_sale_shift", "0");
        this.authService.setIsOpenSaleShift(false);
        // localStorage.setItem("sale_shift", JSON.stringify(saleShift));
        this.authService.setSaleShift(saleShift);
        this.dialogRef.close(res);
        this.notificationService.showSuccessNotification(
          "Open Sale Shift Success."
        );
      },
      err => {
        this.notificationService.showFailureNotification(
          "Open Sale Shift Error."
        );
      }
    );
  }

  isDigit(event) {
    const inputKeyCode = event.keyCode ? event.keyCode : event.which;
    if (inputKeyCode !== null) {
      if (inputKeyCode === 45) {
        event.preventDefault();
      }
      if (inputKeyCode < 48 || inputKeyCode > 57) {
        event.preventDefault();
      }
    }
  }

  notNow() {
    this.dialogRef.close();
    this.authService.logout();
  }
}
