import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { Routes, RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { QRCodeModule } from "angularx-qrcode";
// Material Design
import {
  MatDatepickerModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatInputModule,
  MatDialogModule,
  MatButtonModule,
  MatSnackBarModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatMenuModule,
  MatOptionModule,
  MatSelectModule,
  MatProgressSpinnerModule
} from "@angular/material";
import { MatIconModule } from "@angular/material/icon";
import { LoginComponent } from "./login/login.component";

// ngBootstrap
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxMatDrpModule } from "ngx-mat-daterange-picker";

import { HttpClientModule } from "@angular/common/http";
import { SearchMerchantPipe } from "src/pipes/search-merchant.pipe";
import { SearchTicketPipe } from "src/pipes/search-ticket.pipe";
import { OrderDialogComponent } from "./home/order-dialog/order-dialog.component";
import { PaymentDialogComponent } from "./home/payment-dialog/payment-dialog.component";
import { PrintDialogComponent } from "./home/print-dialog/print-dialog.component";
import { HomeGuard } from "src/security/home.guard";
import { ReportComponent } from "./report/report.component";
import { SearchReportPipe } from "src/pipes/search-report.pipe";
import { PrintManagementComponent } from "./print-management/print-management.component";
import { PosGuard } from "src/security/pos.guard";
import { PosMgrGuard } from "src/security/pos-mgr.guard";
import { LoginGuard } from "src/security/login.guard";
import { SaleShiftDialogComponent } from "./home/sale-shift-dialog/sale-shift-dialog.component";
import { CloseSaleShiftDialogComponent } from "./home/close-sale-shift-dialog/close-sale-shift-dialog.component";
import { TicketComponent } from "./home/ticket/ticket.component";
import { FindTourGuideComponent } from "./home/find-tour-guide/find-tour-guide.component";
import { ChangePasswordDialogComponent } from "./home/change-password-dialog/change-password-dialog.component";
import { SearchDestinationPipe } from "src/pipes/search-destination.pipe";
import { SearchLanguagePipe } from "src/pipes/search-language.pipe";
import { GuideOrderDialogComponent } from "./home/guide-order-dialog/guide-order-dialog.component";
import { GuidePaymentDialogComponent } from "./home/guide-payment-dialog/guide-payment-dialog.component";
import { GuidePrintDialogComponent } from "./home/guide-print-dialog/guide-print-dialog.component";
import { TransportationComponent } from "./home/transportation/transportation.component";
import { TransportationOrderDialogComponent } from "./home/transportation-order-dialog/transportation-order-dialog.component";
import { TransportationPaymentDialogComponent } from "./home/transportation-payment-dialog/transportation-payment-dialog.component";
import { TransportationPrintDialogComponent } from "./home/transportation-print-dialog/transportation-print-dialog.component";

const routes: Routes = [
  { path: "login", component: LoginComponent, canActivate: [LoginGuard] },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [HomeGuard, PosGuard],
    children: [
      { path: "tickets", component: TicketComponent },
      { path: "find-tour-guides", component: FindTourGuideComponent },
      { path: "transportations", component: TransportationComponent },
      { path: "**", redirectTo: "tickets", pathMatch: "full" }
    ]
  },
  {
    path: "report",
    component: ReportComponent,
    canActivate: [HomeGuard, PosGuard]
  },
  {
    path: "print-management",
    component: PrintManagementComponent,
    canActivate: [HomeGuard, PosMgrGuard]
  },
  { path: "**", redirectTo: "home/tickets", pathMatch: "full" }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SearchMerchantPipe,
    SearchTicketPipe,
    SearchReportPipe,
    OrderDialogComponent,
    PaymentDialogComponent,
    PrintDialogComponent,
    ReportComponent,
    PrintManagementComponent,
    SaleShiftDialogComponent,
    CloseSaleShiftDialogComponent,
    TicketComponent,
    FindTourGuideComponent,
    ChangePasswordDialogComponent,
    SearchDestinationPipe,
    SearchLanguagePipe,
    GuideOrderDialogComponent,
    GuidePaymentDialogComponent,
    GuidePrintDialogComponent,
    TransportationComponent,
    TransportationOrderDialogComponent,
    TransportationPaymentDialogComponent,
    TransportationPrintDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    NgbModule,
    FormsModule,
    QRCodeModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Material Design
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    MatSnackBarModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    // ngx date range picker
    NgxMatDrpModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    OrderDialogComponent,
    PaymentDialogComponent,
    PrintDialogComponent,
    SaleShiftDialogComponent,
    CloseSaleShiftDialogComponent,
    ChangePasswordDialogComponent,
    GuideOrderDialogComponent,
    GuidePaymentDialogComponent,
    GuidePrintDialogComponent,
    TransportationOrderDialogComponent,
    TransportationPaymentDialogComponent,
    TransportationPrintDialogComponent
  ]
})
export class AppModule {}
