import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/shared/auth.service";
import { MatSnackBar } from "@angular/material";

@Injectable({
  providedIn: "root"
})
export class PosMgrGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.getRole() === "POS Manager") {
      return true;
    } else {
      this.showSnackBar("Access Denied! You are not a POS manager");
      this.authService.logout();
      this.router.navigateByUrl("login");
      return false;
    }
  }

  showSnackBar(message: string) {
    this.snackbar.open(message, null, {
      duration: 2000,
      panelClass: ["red-snackbar"],
      horizontalPosition: "right"
    });
  }
}
