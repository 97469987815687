import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "src/shared/auth.service";
import { Router, NavigationExtras } from "@angular/router";
import { MatSnackBar } from "@angular/material";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private route: Router,
    private snackbar: MatSnackBar
  ) {}
  loginFormGroup: FormGroup;
  usernameFormControl: FormControl = new FormControl("", [Validators.required]);
  passwordFormControl: FormControl = new FormControl("", [Validators.required]);
  hide = true;

  ngOnInit() {
    this.loginFormGroup = new FormGroup({
      username: this.usernameFormControl,
      password: this.passwordFormControl
    });
  }

  getRequiredErrorMessage(field) {
    return this.loginFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  login() {
    this.authService
      .login(
        this.loginFormGroup.get("username").value,
        this.loginFormGroup.get("password").value
      )
      .subscribe(
        (res: any) => {
          if (res.status !== 200) {
            this.showSnackBar(res.message);
          } else {
            console.log("login result", res);
            this.authService.setUsername(res.data.username);
            this.authService.setUserID(res.data._id);
            this.authService.setToken(res.data.token);
            this.authService.setExpireAt(res.data.expiresAt);
            this.authService.setRole(res.data.role.name);
            if (this.authService.getRole() === "POS Manager") {
              this.route.navigateByUrl("print-management");
            } else {
              const saleShift = res.saleShiftInfo.sale_shift;

              if (saleShift == null) {
                this.authService.setIsCloseSaleShift(false);
              } else {
                let close_date_time = saleShift.close_date_time;
                if (close_date_time == null) {
                  this.authService.setIsCloseSaleShift(false);
                } else {
                  this.authService.setIsCloseSaleShift(true);
                }
              }
              this.authService.setIsOpenSaleShift(
                res.saleShiftInfo.open_sale_shift
              );
              this.authService.setSaleShift(saleShift);
              this.route.navigate(["home/tickets"]);
            }
          }
        },
        error => {
          console.log("error");

          console.log(error);
        }
      );
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.loginFormGroup.invalid) {
        this.login();
      }
    }
  }

  showSnackBar(message: string) {
    this.snackbar.open(message, null, {
      duration: 2000,
      panelClass: ["red-snackbar"],
      horizontalPosition: "right"
    });
  }
}
