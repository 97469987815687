import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) { }

  public showSuccessNotification(message: string) {
    this.snackBar.open(message, null, {
      duration: 3000,
      panelClass: ["green-snackbar"],
      horizontalPosition: "center"
    });
  }

  public showFailureNotification(message: string) {
    this.snackBar.open(message, null, {
      duration: 3000,
      panelClass: ["red-snackbar"],
      horizontalPosition: "center"
    });
  }
}
