import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  API_URL = environment.url;
  constructor(private http: HttpClient, private router: Router) {}

  public login(username: string, password: string) {
    const body = {
      username: username,
      password: password
    };
    return this.http.post(`${this.API_URL}auth`, body);
  }

  public changePassword(
    userID: string,
    currentPassword: string,
    newPassword: string
  ) {
    const body = {
      old_pwd: currentPassword,
      new_pwd: newPassword
    };
    return this.http.patch(
      `${this.API_URL}users/${userID}/change_pwd?token=${localStorage.getItem(
        "token"
      )}`,
      body
    );
  }

  public logout() {
    localStorage.clear();
    this.router.navigateByUrl("login");
  }

  // set block
  public setUserID(userID: string) {
    localStorage.setItem("user_id", userID);
  }

  public setUsername(username: string) {
    localStorage.setItem("username", username);
  }

  public setExpireAt(expire: number) {
    localStorage.setItem("expire_at", expire.toString());
  }

  public setRole(role: string) {
    localStorage.setItem("role", role);
  }

  public setToken(token: string) {
    localStorage.setItem("token", token);
  }

  public setSaleShift(sale_shift: any) {
    localStorage.setItem("sale_shift", JSON.stringify(sale_shift));
  }

  public setExchangeRate(exchange_rate: any) {
    localStorage.setItem("exchange_rate", JSON.stringify(exchange_rate));
  }

  public setIsOpenSaleShift(should_open_sale_shift: Boolean) {
    let isOpen = "false";
    if (should_open_sale_shift) {
      isOpen = "true";
    }
    localStorage.setItem("should_open_sale_shift", isOpen);
  }

  public setIsCloseSaleShift(is_already_close_sale_shift: Boolean) {
    let isClose = "false";
    if (is_already_close_sale_shift) {
      isClose = "true";
    }
    localStorage.setItem("is_already_close_sale_shift", isClose);
  }

  // get block
  public getUserID() {
    return localStorage.getItem("user_id");
  }
  public getUsername() {
    return localStorage.getItem("username");
  }

  public getExpireAt() {
    return localStorage.getItem("expire_at");
  }

  public getRole() {
    return localStorage.getItem("role");
  }

  public getToken() {
    return localStorage.getItem("token");
  }

  public getSaleShift() {
    return localStorage.getItem("sale_shift") == null
      ? null
      : JSON.parse(localStorage.getItem("sale_shift"));
  }

  public getExchangeRate() {
    return JSON.parse(localStorage.getItem("exchange_rate"));
  }

  public getIsOpenSaleShift() {
    return localStorage.getItem("should_open_sale_shift") == "true"
      ? true
      : false;
  }

  public getIsCloseSaleShift() {
    let isClose = localStorage.getItem("is_already_close_sale_shift");
    console.log("IS close: ", isClose);
    if (isClose != null && isClose == "true") {
      return true;
    } else {
      return false;
    }
  }
}
