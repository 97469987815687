import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ApiService } from "src/shared/api.service";
import { AuthService } from "src/shared/auth.service";
import { MatDialog } from "@angular/material";
import { NotificationService } from "src/shared/notification.service";
import { OrderDialogComponent } from "../order-dialog/order-dialog.component";
import { PaymentDialogComponent } from "../payment-dialog/payment-dialog.component";
import { PrintDialogComponent } from "../print-dialog/print-dialog.component";
import { SaleShiftDialogComponent } from "../sale-shift-dialog/sale-shift-dialog.component";
import { CloseSaleShiftDialogComponent } from "../close-sale-shift-dialog/close-sale-shift-dialog.component";
import { InteractionService } from "src/shared/interaction.service";

@Component({
  selector: "app-ticket",
  templateUrl: "./ticket.component.html",
  styleUrls: ["./ticket.component.scss"]
})
export class TicketComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private interactionService: InteractionService
  ) {
    // console.log("is_close_sale: ", authService.getIsCloseSaleShift());
    // console.log("is_open_sale: ", authService.getIsOpenSaleShift());
    // if (this.authService.getIsOpenSaleShift()) {
    //   this.openSaleShiftDialog();
    // }
  }
  currentDate = new Date();

  @Output() messageEvent = new EventEmitter<any>();

  merchants: any;
  selectedMerchant: any;
  destinations: any;
  selectedDestination: any;
  searchKey = "";
  dumData = { _id: "0", name: "null" };
  date = new Date();
  isFav = true;
  isAll = false;
  tickets = [];
  merchantQuery = "";
  orderData: any;
  saleShift: any;
  exchangeRates: any;
  amount_cash_riel = 0;
  amount_cash_dollar = 0;

  ngOnInit() {
    this.selectedMerchant = this.dumData;
    this.selectedDestination = this.dumData;
    this.getAllDestinationTickets();
    // this.getAllMerchants();
    this.getPopularTickets();
    this.getCurrentCash();
    this.interactionService.message$.subscribe(message => {
      if (message === 1) {
        this.getTicketsByDestination();
      } else if (message === 2) {
        this.getCurrentCash();
      }
    });
  }

  getAllMerchants() {
    this.merchants = [];
    this.apiService.getAllMerchants().subscribe(
      (res: any) => {
        if (res.length > 0) {
          this.merchants = res;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getAllDestinationTickets() {
    this.destinations = [];
    this.apiService.getDestinationTickets().subscribe(
      (res: any) => {
        if (res.length > 0) {
          this.destinations = res;
          console.log("destinations:", this.destinations);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getCurrentCash() {
    let sale_shift_id = this.authService.getUserID();
    let saleShift = this.authService.getSaleShift();
    if (saleShift != null) {
      sale_shift_id = saleShift._id;
    }
    this.apiService.getCurrentCash(sale_shift_id).subscribe(
      (res: any) => {
        this.amount_cash_dollar = res.amount_dollar;
        this.amount_cash_riel = res.amount_riel;
      },
      error => {
        console.log(error);
      }
    );
  }

  getTicketsByMerchant() {
    this.tickets = [];
    if (this.selectedMerchant._id !== "0") {
      this.apiService
        .getTicketsList(this.selectedMerchant._id, this.date.getTime())
        .subscribe((res: any) => {
          this.tickets = res;
        });
    } else {
      this.apiService
        .getPopularTickets(this.date.getTime())
        .subscribe((res: any) => {
          this.tickets = res;
        });
    }
  }

  getTicketsByDestination() {
    this.tickets = [];
    if (this.selectedDestination._id !== "0") {
      this.apiService
        .getServiceByDestination(this.selectedDestination._id)
        .subscribe((res: any) => {
          console.log("service:", res);
          this.tickets = res;
        });
    } else {
      this.apiService
        .getPopularTickets(this.date.getTime())
        .subscribe((res: any) => {
          this.tickets = res;
        });
    }
  }

  closeSale() { }

  onDateChange() {
    this.getTicketsByMerchant();
    if (this.isFav) {
      this.getPopularTickets();
    }
    if (this.isAll) {
      this.getAllTickets();
    }
  }

  onMerchantSelect(merchant: any) {
    this.searchKey = "";
    this.isAll = false;
    this.isFav = false;
    this.selectedMerchant = merchant;
    this.getTicketsByMerchant();
  }

  onDestinationSelect(destination: any) {
    this.searchKey = "";
    this.isAll = false;
    this.isFav = false;
    this.selectedDestination = destination;
    // this.getTicketsByMerchant();
    this.getTicketsByDestination();
  }

  onFavClick() {
    this.searchKey = "";
    this.isFav = true;
    this.isAll = false;
    this.selectedMerchant = this.dumData;
    this.getPopularTickets();
  }

  onAllClick() {
    this.isAll = true;
    this.isFav = false;
    this.selectedMerchant = this.dumData;
    this.getAllServices();
  }

  getAllServices() {
    this.tickets = [];
    this.apiService.getAllServices("").subscribe((res: any) => {
      this.tickets = res;
    });
  }

  getAllTickets() {
    this.tickets = [];
    this.apiService
      .getTicketsList("", this.date.getTime())
      .subscribe((res: any) => {
        this.tickets = res;
      });
  }

  getPopularTickets() {
    this.tickets = [];
    this.apiService.getPopularTickets(this.date.getTime()).subscribe(
      (res: any) => {
        this.tickets = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  showReport() {
    window.open("report", "_blank");
  }
  // Dialog section

  openOrderDialog(ticket: any) {
    // console.log("ticket", ticket);
    if (this.authService.getIsCloseSaleShift()) {
      this.notificationService.showFailureNotification(
        "Sale is closed, you cannot sell any service."
      );
    } else {
      const dialogRef = this.dialog.open(OrderDialogComponent, {
        width: "1200px",
        disableClose: false,
        data: {
          is_back: false,
          ticket: ticket
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          this.orderData = result;
          this.openPaymentDialog(this.orderData);
        }
      });
    }
  }

  openPaymentDialog(orderData) {
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      width: "1200px",
      disableClose: true,
      height: "800px",
      data: {
        order: orderData
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result.response.status === 201) {
          this.getTicketsByMerchant();
        }
        if (result !== null && result !== undefined) {
          this.openPrintDialog(result);
        }
      }
    });
  }

  openPrintDialog(data: any) {
    const dialogRef = this.dialog.open(PrintDialogComponent, {
      width: "600px",
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.getCurrentCash();
    });
  }

  openSaleShiftDialog() {
    const dialogRef = this.dialog.open(SaleShiftDialogComponent, {
      width: "600px",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.authService.setSaleShift(result.sale_shift);
      this.getCurrentCash();
    });
  }

  openCloseSaleDialog() {
    console.log("openCloseSaleDialog", this.authService.getIsCloseSaleShift());
    if (!this.authService.getIsCloseSaleShift()) {
      const dialogRef = this.dialog.open(CloseSaleShiftDialogComponent, {
        width: "600px",
        disableClose: true
      });
    } else {
      this.notificationService.showFailureNotification(
        "Cannot close sale. You have already closed it."
      );
    }
  }
}
