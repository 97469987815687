import { Component, OnInit } from "@angular/core";
import { NgxDrpOptions, PresetItem, Range } from "ngx-mat-daterange-picker";
import { MatDialog } from "@angular/material";
import { ApiService } from "src/shared/api.service";
import { AuthService } from "src/shared/auth.service";
import { CloseSaleShiftDialogComponent } from "../home/close-sale-shift-dialog/close-sale-shift-dialog.component";
import { NotificationService } from "src/shared/notification.service";
@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"]
})
export class ReportComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) { }

  range: Range = { fromDate: new Date(), toDate: new Date() };
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  orders = [];
  totalTickets = 0;
  totalAmount = 0;
  currentPage = 1;
  count = 10;
  page_increment = 0;
  searchQuery = "";
  exchangeRates: any;
  amount_cash_riel = 0;
  amount_cash_dollar = 0;

  ngOnInit() {
    const today = new Date();
    const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);

    this.setupPresets();
    this.options = {
      presets: this.presets,
      format: "mediumDate",
      range: { fromDate: today, toDate: today },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false
      }
      // cancelLabel: "Cancel",
      // excludeWeekends:true,
      // fromMinMax: {fromDate:fromMin, toDate:fromMax},
      // toMinMax: {fromDate:toMin, toDate:toMax}
    };

    this.getExchangeRate();
    this.getCurrentCash();
  }

  getExchangeRate() {
    this.exchangeRates = [];
    this.exchangeRates = this.authService.getExchangeRate();
  }

  getCurrentCash() {
    let sale_shift_id = this.authService.getUserID();
    let saleShift = this.authService.getSaleShift();
    console.log(saleShift);
    if (saleShift != null) {
      sale_shift_id = saleShift._id;
    }
    this.apiService.getCurrentCash(sale_shift_id).subscribe(
      (res: any) => {
        this.amount_cash_dollar = res.amount_dollar;
        this.amount_cash_riel = res.amount_riel;
      },
      error => {
        console.log(error);
      }
    );
  }
  // handler function that receives the updated date range object
  updateRange(range: Range) {
    this.range = range;
    this.reload();
  }

  logout() {
    this.authService.logout();
  }

  // helper function to create initial presets
  setupPresets() {
    const backDate = numOfDays => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {
        presetLabel: "Yesterday",
        range: { fromDate: yesterday, toDate: today }
      },
      {
        presetLabel: "Last 7 Days",
        range: { fromDate: minus7, toDate: today }
      },
      {
        presetLabel: "Last 30 Days",
        range: { fromDate: minus30, toDate: today }
      },
      {
        presetLabel: "This Month",
        range: { fromDate: currMonthStart, toDate: currMonthEnd }
      },
      {
        presetLabel: "Last Month",
        range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      }
    ];
  }

  reload() {
    this.orders = [];
    this.totalTickets = 0;
    this.totalAmount = 0;
    let sale_shiftId = this.authService.getSaleShift()._id;
    // console.log("sale_shiftId, ", sale_shiftId);
    if (this.count === 0) {
      this.apiService
        .getOrders(
          this.range.fromDate.getTime(),
          this.range.toDate.getTime(),
          sale_shiftId
        )
        .subscribe(
          (res: any) => {
            this.page_increment = (this.currentPage - 1) * this.count;
            this.orders = res;
            console.log("order:", res);
            if (this.orders.length > 0) {
              this.orders.map(el => {
                console.log(el);
                this.totalTickets += el.order_items[0].quantity;
                this.totalAmount += el.amount_dollar;
              });
            }
          },
          error => {
            console.log(error);
          }
        );
    } else {
      this.apiService
        .getOrders(
          this.range.fromDate.toISOString(),
          this.range.toDate.toISOString(),
          this.currentPage,
          this.count,
          sale_shiftId
        )
        .subscribe(
          (res: any) => {
            this.page_increment = (this.currentPage - 1) * this.count;
            this.orders = res;
            console.log("order:", res);
            if (this.orders.length > 0) {
              this.orders.map(el => {
                this.totalTickets += el.order_items[0].quantity;
                this.totalAmount += el.amount_dollar;
              });
            }
          },
          error => {
            console.log(error);
          }
        );
    }
  }

  openCloseSaleDialog() {
    console.log(this.authService.getIsCloseSaleShift());
    if (!this.authService.getIsCloseSaleShift()) {
      const dialogRef = this.dialog.open(CloseSaleShiftDialogComponent, {
        width: "600px"
      });
    } else {
      this.notificationService.showFailureNotification(
        "Cannot close sale. You have already closed it."
      );
    }
  }

  onPreviousClick() {
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload();
    }
  }

  onNextClick() {
    this.currentPage += 1;
    this.reload();
  }

  onCountChange() {
    this.reload();
  }

  noNext(data: any) {
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onReloadClick() {
    this.reload();
  }
}
