import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/shared/auth.service';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  constructor(private authService: AuthService, private snackbar: MatSnackBar, private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.getToken() === undefined || this.authService.getToken() === null) {
      this.showSnackBar('You must login first!');
      this.router.navigateByUrl('login');
      return false;
    }
    if (this.isSessionExpired()) {
      this.authService.logout();
      this.showSnackBar('Session expired! Please login again!');
      this.router.navigateByUrl('login');
      return false;
    }
    return true;
  }


  isSessionExpired() {
    const expireAt = Number(this.authService.getExpireAt());
    const currentDate = new Date();
    if (currentDate.getTime() >= expireAt) {
      return true;
    } else {
      return false;
    }
  }

  showSnackBar(message: string) {
    this.snackbar.open(message, null, {
      duration: 2000,
      panelClass: ["red-snackbar"],
      horizontalPosition: "right"
    });
  }
}
