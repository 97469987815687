import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchLanguage"
})
export class SearchLanguagePipe implements PipeTransform {
  transform(pipeData, pipeModifier): any {
    console.log("pipeData: ", pipeData);
    if (pipeData !== undefined && pipeData !== null) {
      return pipeData.filter(item => {
        return item["title"].toLowerCase().includes(pipeModifier.toLowerCase());
      });
    }
  }
}
