import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/shared/auth.service";

@Injectable({
  providedIn: "root"
})
export class LoginGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      this.authService.getToken() === undefined ||
      this.authService.getToken() === null
    ) {
      return true;
    } else {
      if (this.authService.getRole() === 'POS') {
        this.router.navigateByUrl('');
        return false;
      }
      if (this.authService.getRole() === 'POS Manager') {
        this.router.navigateByUrl('print-management');
        return false;
      }
      return false;
    }
  }
}
