import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/shared/api.service";
import { NotificationService } from "src/shared/notification.service";
import { MatDialog } from "@angular/material";
import { AuthService } from "src/shared/auth.service";
import { TransportationOrderDialogComponent } from "../transportation-order-dialog/transportation-order-dialog.component";
import { TransportationPaymentDialogComponent } from "../transportation-payment-dialog/transportation-payment-dialog.component";
import { TransportationPrintDialogComponent } from "../transportation-print-dialog/transportation-print-dialog.component";
import { InteractionService } from "src/shared/interaction.service";

@Component({
  selector: "app-transportation",
  templateUrl: "./transportation.component.html",
  styleUrls: ["./transportation.component.scss"]
})
export class TransportationComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private interactionService: InteractionService
  ) { }

  isLoading = false;
  transportations = [];
  ngOnInit() {
    this.getAllTransportation();
    this.interactionService.message$.subscribe(message => {
      if (message === 1) {
        this.getAllTransportation();
      }
    });
  }

  public getAllTransportation() {
    this.isLoading = true;
    this.transportations = [];
    this.apiService.getAllTransportations().subscribe(
      (res: any) => {
        this.isLoading = false;
        this.transportations = res;
        console.log(this.transportations);
      },
      err => {
        this.isLoading = false;
        this.transportations = [];
        this.notificationService.showFailureNotification(
          "Getting transportations error!"
        );
      }
    );
  }

  openOrderDialog(transportation: any) {
    if (this.authService.getIsCloseSaleShift()) {
      this.notificationService.showFailureNotification(
        "Sale is closed, you cannot sell any service."
      );
    } else {
      const dialogRef = this.dialog.open(TransportationOrderDialogComponent, {
        width: "1200px",
        disableClose: false,
        data: {
          is_back: false,
          transportation: transportation
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          this.openPaymentDialog(result);
        }
      });
    }
  }

  openPaymentDialog(data: any) {
    const dialogRef = this.dialog.open(TransportationPaymentDialogComponent, {
      width: "1200px",
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.openPrintDialog(result);
      }
    });
  }

  openPrintDialog(data: any) {
    const dialogRef = this.dialog.open(TransportationPrintDialogComponent, {
      width: "600px",
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.getAllTransportation();
      this.interactionService.sendMessage(2);
    });
  }
}
