import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AuthService } from "src/shared/auth.service";
import { ApiService } from "src/shared/api.service";

@Component({
  selector: "app-print-dialog",
  templateUrl: "./print-dialog.component.html",
  styleUrls: ["./print-dialog.component.scss"]
})
export class PrintDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PrintDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private authService: AuthService,
    private apiService: ApiService
  ) {
    console.log(object);
  }

  posName = "";
  qrcode_data = "";

  ngOnInit() {
    if (this.object.reprint) {
      this.posName = this.object.pos;
    } else {
      this.posName = this.authService.getUsername();
    }
    this.qrcode_data =
      "https://optistech.com?id=" + this.object.response.data.order_items[0];
  }

  onPrint() {
    const printLog = {
      order_id: this.object.response.order_id,
      user_id: this.authService.getUserID()
    };

    this.apiService.createPrintLog(printLog).subscribe(
      (response: any) => {
        console.log(response);
      },
      error => {
        console.log(error);
      }
    );

    var mywindow = window.open("", "PRINT", "height=400,width=600");

    mywindow.document.write(
      "<html><head><title>" +
      document.title +
      "</title>" +
      `
            <style>
            @import url('https://fonts.googleapis.com/css?family=Abel');

            body {
              font-family: 'Abel', sans-serif;
              font-size: 0.9rem !important;
              text-transform: uppercase;
            }

            #print {
              font-family: Abel;
              padding-top: 10px;
              padding-bottom: 10px;
              min-width: 352px;
              color: black;
           }
            #print p {
              text-transform: uppercase;
           }
            .logo {
              width: 100%;
              height: 50px;
              max-height: 50px;
              padding: 5px;
              position: relative;
           }
            .logo img {
              height: 45px;
              max-height: 45px;
              text-align: center;
              display: block;
              margin: auto;
           }
            .border-bottom {
              width: 100%;
              border-bottom: 1px solid black !important;
              display: block;
              margin-top: 10px;
           }
            .top-info {
              width: 100%;
              height: auto;
              padding: 20px;
              padding: 20px 0px 20px 0px;
              color: black;
           }
            .top-info p {
              margin: 0;
              text-align: left;
           }
            .title {
              text-align: center;
              width: 100%;
              color: black;
           }
            .title p {
              font-size: 1.5rem;
           }
            .content {
              width: 100%;
              position: relative;
              min-height: 50px;
           }
            .content table {
              width: 100%;
              border: none;
              text-transform: uppercase;
           }
            .content .left {
              width: 50%;
              min-width: 50%;
              float: left;
              text-align: left;
              min-height: 50px;
              padding-left: 20px;
           }
            .content .left p {
              margin: 0;
           }
            .content .right {
              width: 50%;
              min-width: 50%;
              float: right;
              text-align: right;
              min-height: 50px;
              padding-right: 20px;
           }
            .content .right p {
              margin: 0;
           }
            .text-right {
              text-align: right;
           }
            .qr-section {
              width: 100%;
              min-height: 150px;
           }
            .qr-section .qr-content {
              width: 150px;
              text-align: center;
              display: block;
              margin: auto;
              margin-top: 10px;
              margin-bottom: 10px;
           }
            .qr-section .qr-content img {
              width: 100%;
           }
            .qr-section p {
              text-align: center;
              margin: 0;
           }
            .contact {
              width: 100%;
           }
            .contact p {
              text-align: center;
              margin-top: 10px;
           }
            .btn-print {
              background-color: #3c9ae2;
              color: white;
           }
            .clear {
              clear: both;
           }
            </style>
        `
    );
    mywindow.document.write("</head><body >");
    console.log(document.getElementById("print").innerHTML);
    mywindow.document.write(document.getElementById("print").innerHTML);
    mywindow.document.write("</body></html>");

    // mywindow.document.close(); // necessary for IE >= 10
    // mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
    // window.location.reload();
    this.dialogRef.close();
    return true;
  }
}
