import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { AuthService } from "src/shared/auth.service";
import { ApiService } from "src/shared/api.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { NotificationService } from "src/shared/notification.service";

@Component({
  selector: "app-close-sale-shift-dialog",
  templateUrl: "./close-sale-shift-dialog.component.html",
  styleUrls: ["./close-sale-shift-dialog.component.scss"]
})
export class CloseSaleShiftDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CloseSaleShiftDialogComponent>,
    private authService: AuthService,
    private apiService: ApiService,
    private notificationService: NotificationService
  ) {}

  closeSaleShiftFormGroup: FormGroup;
  cashInUSDControl = new FormControl("", [Validators.required]);
  cashInKHRControl = new FormControl("", [Validators.required]);

  getRequiredErrorMessage(field) {
    return this.closeSaleShiftFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  ngOnInit() {
    this.closeSaleShiftFormGroup = new FormGroup({
      cash_in_usd: this.cashInUSDControl,
      cash_in_khr: this.cashInKHRControl
    });
    this.getCurrentSaleShiftCash();
  }

  onCancel() {
    this.dialogRef.close();
  }

  getCurrentSaleShiftCash() {
    let sale_shift_id = this.authService.getUserID();
    let saleShift = this.authService.getSaleShift();
    if (saleShift != null) {
      sale_shift_id = saleShift._id;
    }
    this.apiService.getCurrentCash(sale_shift_id).subscribe(
      (res: any) => {
        this.cashInUSDControl.setValue(res.amount_dollar);
        this.cashInKHRControl.setValue(res.amount_riel);
      },
      error => {
        console.log(error);
      }
    );
  }

  isDigit(event) {
    const inputKeyCode = event.keyCode ? event.keyCode : event.which;
    if (inputKeyCode !== null) {
      if (inputKeyCode === 45) {
        event.preventDefault();
      }
      if (inputKeyCode < 48 || inputKeyCode > 57) {
        event.preventDefault();
      }
    }
  }

  closeSaleShift() {
    const currentMilli = new Date().getTime();
    const id = this.authService.getSaleShift()._id;
    console.log("local data: ", id);

    const data = {
      pos: this.authService.getUserID(),
      close_amount_dollar: this.closeSaleShiftFormGroup.get("cash_in_usd")
        .value,
      close_amount_riel: this.closeSaleShiftFormGroup.get("cash_in_khr").value,
      close_date_time: currentMilli,
      is_close: true
    };
    this.apiService.closeSaleShift(id, data).subscribe(
      (res: any) => {
        this.authService.setIsCloseSaleShift(true);
        this.dialogRef.close();
        this.notificationService.showSuccessNotification(
          "Close Sale Shift Success."
        );
      },
      err => {
        console.log(err);
        this.dialogRef.close();
        this.notificationService.showFailureNotification(
          "Close Sale Shift Error."
        );
      }
    );
  }
}
