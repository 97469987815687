import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ApiService } from "src/shared/api.service";
import { AuthService } from "src/shared/auth.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { TransportationOrderDialogComponent } from "../transportation-order-dialog/transportation-order-dialog.component";
import { TransportationPrintDialogComponent } from "../transportation-print-dialog/transportation-print-dialog.component";
import { InteractionService } from "src/shared/interaction.service";

@Component({
	selector: "app-transportation-payment-dialog",
	templateUrl: "./transportation-payment-dialog.component.html",
	styleUrls: ["./transportation-payment-dialog.component.scss"]
})
export class TransportationPaymentDialogComponent implements OnInit {
	currencies: any;
	exchange_rate_dollar_to_riel = 0;
	exchange_rate_yuan_riel = 0;
	amount_riel = 0;
	amount_dollar = 0;
	amount_yuan = 0;
	isLoading = false;
	currentDate = new Date();
	constructor(
		public dialogRef: MatDialogRef<TransportationPaymentDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public object: any,
		private dialog: MatDialog,
		private apiService: ApiService,
		private authService: AuthService,
		private interactionService: InteractionService
	) { }

	transportationPaymentFormGroup: FormGroup;
	cashReceivedUsdControl = new FormControl("", [Validators.required]);
	cashReceivedKhrControl = new FormControl("", [Validators.required]);
	changeUsdControl = new FormControl(0, [Validators.required]);
	changeKhrControl = new FormControl(0, [Validators.required]);
	getRequiredErrorMessage(field) {
		return this.transportationPaymentFormGroup.get(field).hasError("required")
			? "You must enter a value"
			: "";
	}

	ngOnInit() {
		console.log("order_data: ", this.object);
		this.transportationPaymentFormGroup = new FormGroup({
			cashReceivedUsdControl: this.cashReceivedUsdControl,
			cashReceivedKhrControl: this.cashReceivedKhrControl,
			changeUsdControl: this.changeUsdControl,
			changeKhrControl: this.changeKhrControl
		});
		this.getCurrencies();
		this.amount_dollar = this.object.amount;
		this.amount_riel = this.object.amount * this.exchange_rate_dollar_to_riel;
		this.amount_yuan = this.object.amount * this.exchange_rate_yuan_riel;
	}

	getCurrencies() {
		this.currencies = this.authService.getExchangeRate();
		this.exchange_rate_dollar_to_riel = this.currencies[0].to_riel;
		this.exchange_rate_yuan_riel = this.currencies[1].to_riel;
	}

	calculateChange() {
		let cashReceivedUSD = this.transportationPaymentFormGroup.get(
			"cashReceivedUsdControl"
		).value;
		let cashReceivedKHR = this.transportationPaymentFormGroup.get(
			"cashReceivedKhrControl"
		).value;
		let totalReceived =
			Number(cashReceivedUSD) +
			Number(cashReceivedKHR / this.exchange_rate_dollar_to_riel);
		let change = totalReceived - this.amount_dollar;
		if (Number(change) >= 0) {
			this.transportationPaymentFormGroup
				.get("changeUsdControl")
				.setValue(Math.round(change * 100) / 100);
			this.transportationPaymentFormGroup
				.get("changeKhrControl")
				.setValue(Math.floor(change * this.exchange_rate_dollar_to_riel));
		} else {
			this.transportationPaymentFormGroup
				.get("changeUsdControl")
				.setValue(null);
			this.transportationPaymentFormGroup
				.get("changeKhrControl")
				.setValue(null);
		}
		this.transportationPaymentFormGroup.updateValueAndValidity();
	}

	formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
		try {
			decimalCount = Math.abs(decimalCount);
			decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

			const negativeSign = amount < 0 ? "-" : "";

			let i = parseInt(
				(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
			).toString();
			let j = i.length > 3 ? i.length % 3 : 0;

			return (
				negativeSign +
				(j ? i.substr(0, j) + thousands : "") +
				i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
				(decimalCount
					? decimal +
					Math.abs(amount - +i)
						.toFixed(decimalCount)
						.slice(2)
					: "")
			);
		} catch (e) {
			console.log(e);
		}
	}
	onUSDCashReceivedChange() {
		const receivedUSD = this.transportationPaymentFormGroup.get(
			"cashReceivedUsdControl"
		).value;
		if (Number(receivedUSD) < this.amount_dollar) {
			const neededKHR =
				(Number(this.amount_dollar) - Number(receivedUSD)) *
				this.exchange_rate_dollar_to_riel;
			this.transportationPaymentFormGroup
				.get("cashReceivedKhrControl")
				.setValue(neededKHR);
		} else {
			this.transportationPaymentFormGroup
				.get("cashReceivedKhrControl")
				.setValue(0);
		}
		this.calculateChange();
	}

	onKHRCashReceivedChange() {
		const receivedKHR = this.transportationPaymentFormGroup.get(
			"cashReceivedKhrControl"
		).value;
		if (
			Number(Math.round(receivedKHR / this.exchange_rate_dollar_to_riel)) <
			Number(this.amount_dollar)
		) {
			const neededUSD =
				Number(this.amount_dollar) -
				Number(Math.round(receivedKHR / this.exchange_rate_dollar_to_riel));
			this.transportationPaymentFormGroup
				.get("cashReceivedUsdControl")
				.setValue(neededUSD);
		} else {
			this.transportationPaymentFormGroup
				.get("cashReceivedUsdControl")
				.setValue(0);
		}
		this.calculateChange();
	}

	openOrderDialog() {
		this.dialogRef.close();
		const backData = {
			is_back: true,
			transportation: this.object.service,
			from_date: this.object.from_date,
			to_date: this.object.to_date,
			used_date: this.object.used_date,
			buyer_name: this.object.buyer_name,
			buyer_phone_number: this.object.buyer_phone_number,
			buyer_address: this.object.buyer_address,
			quantity: this.object.quantity,
			destination: this.object.destination,
			merchant: this.object.merchant,
			price: this.object.price,
			option: this.object.option,
			amount: this.object.amount,
			availability: this.object.availability,
			remark: this.object.remark
		};
		const dialogRef = this.dialog.open(TransportationOrderDialogComponent, {
			width: "1200px",
			data: backData
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result !== undefined) {
				this.openPaymentDialog(result);
			}
		});
	}

	openPaymentDialog(data: any) {
		const dialogRef = this.dialog.open(TransportationPaymentDialogComponent, {
			width: "1200px",
			data: data
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result !== undefined) {
				this.openPrintDialog(result);
			}
		});
	}

	openPrintDialog(data: any) {
		const dialogRef = this.dialog.open(TransportationPrintDialogComponent, {
			width: "600px",
			disableClose: true,
			data: data
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log(result);
			this.interactionService.sendMessage(1);
			this.interactionService.sendMessage(2);
			// this.getTourGuides();
			// this.getCurrentCash();
		});
	}

	onBack() {
		this.openOrderDialog();
	}

	submitPayment() {
		console.log("Object: ", this.object);
		const paymentData = {

			order_items: [
				{
					quantity: this.object.quantity,
					total_price: this.amount_dollar,
					original_total_price: this.object.option.original_price * this.object.quantity,
					from_date: this.object.used_date,
					to_date: this.object.used_date,
					used_date: this.object.used_date,
					remark: this.object.remark,
					option: this.object.option,
					merchant: this.object.merchant._id,
					type: "transportation",
				}
			],



			total_amount: this.amount_dollar,
			amount_riel: this.amount_riel,
			amount_dollar: this.amount_dollar,
			amount_yuan: this.amount_yuan,

			exchange_rate: this.currencies[0],

			buyer_name: this.object.buyer_name,
			buyer_phone_number: this.object.buyer_number,
			buyer_address: this.object.buyer_address,

			pos: this.authService.getUserID(),
			sale_shift: this.authService.getSaleShift()._id,

			received_cash_dollar: this.cashReceivedUsdControl.value,
			received_cash_riel: this.cashReceivedKhrControl.value,
			received_cash_yuan: 0,

			dollar_change: this.changeUsdControl.value,
			riel_change: this.changeKhrControl.value,
			yuan_change: 0
		};

		console.log("payment_data: ", paymentData);
		let printData: any = null;
		this.isLoading = true;
		this.apiService.createOrder(paymentData).subscribe(
			res => {

				printData = {
					response: res,
					payment_data: paymentData,
					transportation: this.object.service
				};
				console.log("printData: ", res);
				this.dialogRef.close(printData);
				this.isLoading = false;
			},
			error => {
				console.log(error);
				this.dialogRef.close();
			}
		);
	}
}
